import React from 'react'
import TableRow from '@mui/material/TableRow'
import { type Log } from './Logs.types'
import AdvancedTable from 'components/AdvancedTable'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { renderDate } from 'utils/renderDate'
import { StatusTypes } from 'pages/Applications/applicationStatuses'
import { renderCells } from 'utils/renderCells'
import useQueryGet from 'hooks/useQueryGet'
import RequestStatus from 'components/RequestStatus'

const Logs = (): JSX.Element => {
  const { t } = useTranslation()
  const { data, isLoading } = useQueryGet<string[]>({ queryKey: ['log-names'], endpoint: 'logs/names' })

  if (!(data instanceof Array) && ((data !== undefined && 'error' in data) || isLoading)) {
    return <RequestStatus data={data} isLoading={isLoading} />
  }

  return (
    <>
      <Typography variant='h4' component='h1' mb={2}>{t('navigation.logs')}</Typography>
      <AdvancedTable<Log>
        name='logs'
        endpoint='logs'
        orderField='createdAt'
        orderDir='DESC'
        headings={[
          { type: 'empty', name: 'ID', field: 'id', sort: true, width: 90 },
          { type: 'options', name: t('common.name'), field: 'name', sort: true },
          { type: 'datetime-range', name: t('common.createdAt'), field: 'createdAt', sort: true, width: 120 },
          { type: 'empty', name: t('common.ip'), field: '', width: 180 },
          { type: 'text', name: t('common.email'), field: 'email', width: 220 }
        ]}
        options={{
          name: data?.map(name => ({ id: name, value: name }))
        }}
        renderRow={(row, idx) => (
          <TableRow key={idx}>
            {renderCells([
              { label: 'ID', value: row.id },
              { label: t('common.name'), breakWord: true, value: <><strong>{row.name}</strong><br />{row.name.includes('application status change') ? StatusTypes[+row.description] : <small>{row.description}</small>}</> },
              { label: t('common.createdAt'), value: renderDate(row.createdAt, true) },
              { label: t('common.ip'), value: row.ip !== undefined && row.ip !== '' ? row.ip : 'N/A' },
              { label: t('common.email'), value: <><strong>{row.user?.name}</strong><br /> {row.user?.email}</> }
            ])}
          </TableRow>
        )}
      />
    </>
  )
}

export default Logs

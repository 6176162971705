import React from 'react'
import { Box, FormControlLabel, Switch, TextField, Typography } from '@mui/material'
import type { InputProps } from './Input.types'
import { type FieldValues, useController } from 'react-hook-form'
import { DatePicker } from '@mui/x-date-pickers'
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker'

const Input: <T extends FieldValues>(props: InputProps<T>) => JSX.Element = ({ control, name, label, type = 'text', textInputProps, dateInputProps, switchInputProps, datetimeInputProps, wrapperProps }) => {
  const { fieldState: { error }, field } = useController({ name, control })

  switch (type) {
    case 'text': {
      return (
        <TextField
          {...{ ...textInputProps, ...field }}
          fullWidth
          id={name}
          label={label}
          error={Boolean(error?.message)}
          helperText={error?.message !== undefined ? <>{error?.message}</> : (textInputProps?.helperText)}
        />
      )
    }
    case 'date': {
      return (
        <Box {...wrapperProps}>
          <DatePicker
            sx={{ width: '100%' }}
            label={label}
            {...{ ...field, ...dateInputProps, format: dateInputProps?.format ?? 'd/M/yy' }}
            slotProps={{ ...dateInputProps?.slotProps, actionBar: { actions: ['clear'] } }}
          />
          {error?.message !== undefined
            ? <Typography whiteSpace='break-spaces' fontSize={11} color='error'><>{error?.message}</></Typography>
            : null}
        </Box>
      )
    }
    case 'datetime': {
      return (
        <Box {...wrapperProps}>
          <MobileDateTimePicker
            label={label}
            {...{ ...datetimeInputProps, ...field }}
            ampm={true}
            orientation="landscape"
          />
          {error?.message !== undefined
            ? <Typography whiteSpace='break-spaces' fontSize={11} color='error'><>{error?.message}</></Typography>
            : null}
        </Box>
      )
    }
    case 'switch': {
      return (
        <FormControlLabel
          value={field.value}
          checked={typeof field.value === 'boolean' ? field.value : field.value === 'true'}
          control={
            <Switch
              {...switchInputProps}
              defaultChecked={switchInputProps?.defaultChecked}
              checked={field.value}
              onChange={(e, val) => {
                field.onChange(val)
                if (switchInputProps !== undefined && 'onChange' in switchInputProps) {
                  switchInputProps?.onChange?.(e, val)
                }
                return val
              }}
            />
          }
          label={label} />
      )
    }
  }
}

export default Input

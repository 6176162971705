import React from 'react'
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined'
import { Badge, Box, Button, Checkbox, Drawer, ListItem, ListItemText, MenuItem, List, Typography } from '@mui/material'
import Select from 'components/Select'
import { useFilters } from './FiltersContext'
import { useTranslation } from 'react-i18next'
import { useForm, type FieldValues } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import type { Filters, Option } from './FiltersContext.types'
import Input from 'components/Input'
import { useAppContext } from 'context/AppContext/AppContext'

const FiltersList = (): JSX.Element => {
  const { markets } = useAppContext()
  const { t } = useTranslation()
  const {
    filters,
    toggleDrawer,
    isOpen,
    headings,
    options,
    customFilters,
    changeFilters,
    isExportMode,
    exportText,
    changeExportMode
  } = useFilters()

  const localDatePickers = [...headings, ...customFilters].filter(el => el.name.toLowerCase().includes('local'))

  const defaultValues: (type: 'reset' | 'load') => Record<string, string | boolean | null | string[] | Date> = (type) =>
    [...headings, ...customFilters].reduce<Record<string, string | boolean | null | string[] | Date>>((a, b) => {
      switch (b.type) {
        case 'text': {
          return {
            ...a,
            [b.field]: type === 'reset' || !filters[b.field] ? '' : filters[b.field]
          }
        }
        case 'date-range':
        case 'datetime-range': {
          return {
            ...a,
            [`${b.field}Start`]: type === 'reset' || !filters[`${b.field}Start`] ? null : new Date(filters[`${b.field}Start`] as string),
            [`${b.field}Finish`]: type === 'reset' || !filters[`${b.field}Finish`] ? null : new Date(filters[`${b.field}Finish`] as string)
          }
        }
        case 'options': {
          return {
            ...a,
            [b.field]: type === 'reset' || !filters[b.field] ? '' : filters[b.field]
          }
        }
        case 'multipicker': {
          return {
            ...a,
            [b.field]: type === 'reset' || !filters[b.field] ? [] : filters[b.field]
          }
        }
        case 'boolean': {
          return {
            ...a,
            [b.field]: type === 'reset' || !filters[b.field] ? false : String(filters[b.field]) === 'true'
          }
        }
        default: {
          return { ...a }
        }
      }
    }, {})

  const validationObject = yup.object(headings.reduce<yup.ObjectShape>((a, b) => {
    if (b.type === 'date-range' || b.type === 'datetime-range') {
      return {
        ...a,
        [`${b.field}Start`]: yup.date().nullable(),
        [`${b.field}Finish`]: yup.date().nullable().when(`${b.field}Start`, ([enteredDate], schema) => {
          if (enteredDate !== null) {
            return schema.min(enteredDate, t('forms.finishLaterThanStart') ?? 'The end date must be later than the start date')
          }

          return schema
        })
      }
    }
    return { ...a }
  }, {}))

  const { handleSubmit, control, getValues, watch } = useForm({
    values: defaultValues('load'),
    resolver: yupResolver(validationObject)
  })
  const isMarketSelected = watch('marketId')

  const handleSaveFilters = (data: any): void => {
    const newFilters = Object.fromEntries(Object.entries({
      ...data,
      page: 1
    }).filter(([key, value]) => value !== '' && value !== null && value !== undefined && !(value instanceof Array && value.length === 0))) as Filters
    changeFilters(newFilters)
  }

  const clearFilters = (): void => {
    changeFilters({})
    changeExportMode?.(false)
  }

  return (
    <div>
      <Box>
        <Badge
          badgeContent={Object.entries(filters).filter(([key, value]) => !(['order', 'orderField', 'take', 'page', 'tab'].includes(key) || (key === 'status' && value instanceof Array && value.length === 0))).length}
          color="error">
          <Button sx={{ mb: 1 }} variant='contained' size='small' onClick={() => {
            toggleDrawer()
          }} startIcon={<FilterListOutlinedIcon />}>
            {t('common.filter')}
          </Button>
        </Badge>
      </Box>
      <Drawer
        anchor='right'
        open={isOpen}
        onClose={() => {
          toggleDrawer(false)
          changeExportMode?.(false)
        }}
      >
        <form onSubmit={handleSubmit(handleSaveFilters)}>
          <List sx={{ paddingTop: 8, maxWidth: '100vw', width: 320, '@media (min-width: 768px)': { width: 600 } }}>
            {[...headings, ...customFilters].sort((a, b) => (a.order !== undefined && b.order !== undefined) ? a.order - b.order : -1).map(({
              field,
              name,
              type
            }) => {
              if (field === '') {
                return null
              }
              switch (type) {
                case 'options': {
                  return (
                    <ListItem key={field} sx={{ display: 'flex', mx: 0 }}>
                      <Select
                        control={control}
                        elements={[...(options[field]?.filter(e => e.value)) ?? [], {
                          id: '',
                          value: 'Not selected'
                        }]}
                        label={name}
                        name={field}
                        formControlProps={{ size: 'small', sx: { mx: 0 } }}
                        MenuProps={{
                          PaperProps: { style: { boxShadow: '0 0 8px #777' } }
                        }}
                        renderFunction={(option: Option, idx) => (
                          <MenuItem key={idx} value={option.id}>{option.value}</MenuItem>
                        )}
                      />
                    </ListItem>
                  )
                }
                case 'multipicker': {
                  const optionsToShow = [...(options[field]?.filter(e => e.value)) ?? [], {
                    id: '',
                    value: 'Not selected'
                  }]
                  const renderValues = (ids: string[]): string => optionsToShow.filter(option => ids.includes(option.id)).map(e => e.value).join(', ')

                  return (
                    <ListItem key={field} sx={{ display: 'flex', mx: 0 }}>
                      <Select<Option, FieldValues>
                        control={control}
                        elements={optionsToShow}
                        label={name}
                        multiple
                        formControlProps={{ size: 'small', sx: { mx: 0 } }}
                        MenuProps={{
                          PaperProps: { style: { boxShadow: '0 0 8px #777' } }
                        }}
                        name={field}
                        // @ts-expect-error-next-line
                        renderValue={renderValues}
                        renderFunction={(option, idx) => (
                          <MenuItem key={idx} value={option.id}>
                            <Checkbox
                              checked={(getValues(field) as string[])?.includes(option.id)}
                              sx={{ p: 0.5 }} />
                            <ListItemText sx={{ fontSize: 16 }} primary={option.value} />
                          </MenuItem>
                        )}
                      />
                    </ListItem>
                  )
                }
                case 'text': {
                  return (
                    <ListItem key={field} sx={{ display: 'flex', mx: 0 }}>
                      <Input
                        control={control}
                        label={name}
                        name={field}
                        textInputProps={{ size: 'small', sx: { mx: 0 } }}
                      />
                    </ListItem>
                  )
                }
                case 'date-range':
                case 'datetime-range': {
                  const isLocal = field.toLowerCase().includes('local')
                  const hasLocalMatch = !isLocal && localDatePickers.some(el => el.type.includes(type))

                  const content = (
                    <Box display='flex' alignItems='center' flexWrap='wrap' gap={1} width='100%'>
                      <Input
                        wrapperProps={{ flexBasis: '250px', flexGrow: 1 }}
                        type={type.replace('-range', '') as 'date' | 'datetime'}
                        name={`${field}Start`}
                        label={`${name} Start`}
                        control={control}
                        dateInputProps={{
                          slotProps: {
                            textField: {
                              size: 'small',
                              sx: { mx: 0 },
                              fullWidth: true
                            }
                          }
                        }}
                        datetimeInputProps={{
                          slotProps: {
                            textField: {
                              size: 'small',
                              sx: { mx: 0 },
                              fullWidth: true
                            }
                          }
                        }}
                      />
                      <Input
                        wrapperProps={{ flexBasis: '250px', flexGrow: 1 }}
                        type={type.replace('-range', '') as 'date' | 'datetime'}
                        name={`${field}Finish`}
                        label={`${name} Finish`}
                        control={control}
                        dateInputProps={{
                          slotProps: {
                            textField: {
                              size: 'small',
                              sx: { mx: 0 },
                              fullWidth: true
                            }
                          }
                        }}
                        datetimeInputProps={{
                          slotProps: {
                            textField: {
                              size: 'small',
                              sx: { mx: 0 },
                              fullWidth: true
                            }
                          }
                        }}
                      />
                    </Box>
                  )

                  if (isLocal && isMarketSelected) {
                    return (
                      <ListItem key={field} sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                        <Typography fontSize='14px'>{name} time</Typography>
                        {content}
                      </ListItem>
                    )
                  }

                  if (isLocal) {
                    if ((markets != null) && markets.data.length > 1) {
                      return null
                    } else {
                      return (
                        <ListItem key={field} sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                          <Typography fontSize='14px'>{name} time</Typography>
                          {content}
                        </ListItem>
                      )
                    }
                  } else {
                    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                    if ((!isMarketSelected && ((markets != null) && markets.data.length > 1)) || !hasLocalMatch) {
                      return (
                        <ListItem key={field} sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                          <Typography fontSize='14px'>Server timezone ({process.env.REACT_APP_API_TIMEZONE})</Typography>
                          {content}
                        </ListItem>
                      )
                    } else {
                      return null
                    }
                  }
                }
                case 'boolean':
                  return (
                    <ListItem key={field} sx={{ display: 'block' }}>
                      <Input
                        type='switch'
                        name={field}
                        label={name}
                        control={control}
                      />
                    </ListItem>
                  )
                default: {
                  return null
                }
              }
            })}
            <Box sx={{ px: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
              {!isMarketSelected && (localDatePickers.length > 0 && (markets != null) && markets.data?.length > 1) && <Typography fontWeight='700' color='warning.main' fontSize='12px'>When downloading the database, select a single market if you want a detailed data.</Typography>}
              <Button variant='contained' color='success'
                type='submit'>{isExportMode ? (exportText ?? t('common.export')) : t('common.saveFilters')}</Button>
              <Button variant='contained' onClick={clearFilters}>{t('common.clearFilters')}</Button>
              <Button variant='outlined' color='error' onClick={() => {
                toggleDrawer(false)
                changeExportMode?.(false)
              }}>{t('common.cancel')}</Button>
            </Box>
          </List>
        </form>
      </Drawer>
    </div>
  )
}

export default FiltersList

import { type Control, useController } from 'react-hook-form'
import React, { useMemo } from 'react'
import type { HandleSubmitProps, MarketFormField } from './MarketForm.types'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Input from 'components/Input/Input'
import { useTheme } from '@mui/material/styles'

const FormsElement = ({ control, field }: { control: Control<HandleSubmitProps, any>, field: { code: MarketFormField } }): JSX.Element => {
  const { t } = useTranslation()
  const { field: { onChange } } = useController({ control, name: `forms.${field.code}.required` })

  const handleVisibilityChange = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    if (!checked) {
      onChange(false)
    }
  }

  return (
    <>
      <Input<HandleSubmitProps>
        control={control}
        type='switch'
        name={`forms.${field.code}.visible`}
        label={t(`application.${field.code}`)}
        switchInputProps={{ onChange: handleVisibilityChange }}
      />
      <Input<HandleSubmitProps>
        control={control}
        type='switch'
        name={`forms.${field.code}.required`}
        label={t('common.required')}
      />
    </>
  )
}

const Forms = ({ control }: { control: Control<HandleSubmitProps, any> }): JSX.Element => {
  const theme = useTheme()
  const { field: { value } } = useController({ control, name: 'receiptValidation' })
  const { field: { value: verification } } = useController({ control, name: 'verification' })
  const { field: { value: forms } } = useController({ control, name: 'forms' })

  const fields: Array<{ code: MarketFormField }> = useMemo(() => [
    { code: 'barcode' },
    { code: 'email' },
    { code: 'phoneNumber' },
    { code: 'idNumber' },
    { code: 'openQuestion' },
    { code: 'closedQuestion' },
    { code: 'prizeSelection' },
    { code: 'streetName' },
    { code: 'buildingName' },
    { code: 'buildingNumber' },
    { code: 'apartmentNumber' },
    { code: 'postalCode' },
    { code: 'city' },
    { code: 'country' },
    { code: 'region' },
    { code: 'province' },
    { code: 'comments' },
    { code: 'files' },
    { code: 'dropdown' },
    { code: 'invoiceNumber' }
  ], [value, verification, forms])

  const inputs = useMemo(() => (
    <>
      {fields.map((field, idx) => (
        <Box key={field.code} sx={{ borderBottom: idx !== fields.length - 1 ? `1px solid ${theme.palette.grey[300]}` : '' }} display='flex' justifyContent='space-between' component="li">
          <FormsElement control={control} field={field} />
        </Box>
      ))}
    </>
  ), [fields])

  return (
    <Box key={JSON.stringify(forms)} component="ul">
      {inputs}
    </Box>
  )
}

export default Forms

import React, { useState, useEffect } from 'react'
import type { DataToSend, HandleSubmitProps } from './MarketForm.types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/system/Box'
import { languages } from 'config/languages'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { type Continent } from 'types/Continent'
import MarketFormFields from './MarketFormFields'
import { axiosPost } from 'connectors/axiosPost'
import type { Market } from 'types/Market'
import RequestStatus from 'components/RequestStatus'
import { Button, Typography, Card, CardHeader, CardContent, CardActions } from '@mui/material'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import { format } from 'date-fns-tz'
import { useAlert } from 'context/AlertContext'
import useQueryGet from 'hooks/useQueryGet'
import { useAuthContext } from 'context/AuthContext/AuthContext'

const MarketAddForm = (): JSX.Element => {
  const navigate = useNavigate()
  const [errorMsg, setErrorMsg] = useState<string>('')
  const { t } = useTranslation()
  const { token, refreshToken } = useAuthContext()
  const { changeMessage } = useAlert()
  const queryClient = useQueryClient()

  const { data: continents, isLoading: loadingContinents } = useQueryGet<Continent[]>({
    queryKey: ['market-groups', 'continents'],
    endpoint: 'markets/groups'
  })

  const { mutate: addMarket, data: addResponse, status, reset } = useMutation(
    async (market: DataToSend) => await axiosPost<Market>(token, refreshToken, 'markets', { ...market }))

  const resetSuccess = (): void => {
    reset()
    void queryClient.refetchQueries({ queryKey: ['all-markets'] })
    navigate('/markets')
  }

  const onHandleSubmit = async (data: HandleSubmitProps): Promise<any> => {
    setErrorMsg('')
    const body = {
      ...data,
      name: data.name.label,
      code: data.name.code,
      languages: data.languages,
      groupId: data.groupId,
      applicationHourReminder: Number(data.applicationHourReminder),
      holdingPageDate: format(new Date(data.holdingPageDate), 'yyyy-MM-dd HH:mm:ss+00:00'),
      promoLiveDate: format(new Date(data.promoLiveDate), 'yyyy-MM-dd HH:mm:ss+00:00'),
      promoTestLiveDate: format(new Date(data.promoTestLiveDate), 'yyyy-MM-dd HH:mm:ss+00:00'),
      promoEndDate: format(new Date(data.promoEndDate), 'yyyy-MM-dd HH:mm:ss+00:00'),
      closedPageEndDate: format(new Date(data.closedPageEndDate), 'yyyy-MM-dd HH:mm:ss+00:00'),
      personalDataDeletedBy: format(new Date(data.personalDataDeletedBy), 'yyyy-MM-dd HH:mm:ss+00:00'),
      forms: Object.entries(data.forms).map(([key, values]) => ({ name: key, ...values })) as DataToSend['forms']
    }
    addMarket(body)
  }

  useEffect(() => {
    if (addResponse !== undefined && 'error' in addResponse) {
      setErrorMsg(addResponse.error?.data.message)
    }
  }, [addResponse])

  const languagesArray = Object.entries(languages).map(([key, lang]) => ({ key, name: lang.name, nativeName: lang.nativeName }))

  useEffect(() => {
    if (errorMsg !== '') {
      changeMessage(errorMsg, 'error', reset)
    }
    if (addResponse !== undefined && status === 'success' && !('error' in addResponse)) {
      changeMessage(t('forms.fieldCreated', { field: t('singleField.market') }), 'success', resetSuccess)
    }
  }, [errorMsg, addResponse, status])

  if (continents === undefined || 'error' in continents) {
    return (
      <RequestStatus data={continents} isLoading={loadingContinents} />
    )
  }

  return (
    <>
      <Typography variant='h4' component='h1' mb={2}>{t('common.addField', { field: 'Market' })}</Typography>
      <Card>
        <CardHeader title={<Button
          type='button'
          variant='outlined'
          onClick={() => { navigate('/markets') }}
          startIcon={<ChevronLeft />}
        >
          {t('common.backListText')}
        </Button>} />
        <CardContent>
          <Box sx={{ maxWidth: 640, width: '100%' }}>
            <MarketFormFields
              continents={continents}
              isAddForm
              languagesArray={languagesArray}
              onHandleSubmit={onHandleSubmit}
            />
          </Box>
        </CardContent>
        <CardActions>
          <Button
            type='button'
            variant='outlined'
            onClick={() => { navigate('/markets') }}
            startIcon={<ChevronLeft />}
          >
            {t('common.backListText')}
          </Button>
        </CardActions>
      </Card>
    </>
  )
}

export default MarketAddForm

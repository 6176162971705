import React, { useState, useEffect } from 'react'
import type { ApiLanguage, DataToSend, HandleSubmitProps, MarketFormField } from './MarketForm.types'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import Box from '@mui/system/Box'
import { languages } from 'config/languages'
import { useMutation } from '@tanstack/react-query'
import { type Continent } from 'types/Continent'
import MarketFormFields from './MarketFormFields'
import { axiosPost } from 'connectors/axiosPost'
import type { ApiMarket, Market } from 'types/Market'
import RequestStatus from 'components/RequestStatus'
import { Button, Typography, Card, CardHeader, CardContent, CardActions } from '@mui/material'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import { format } from 'date-fns-tz'
import { useAlert } from 'context/AlertContext'
import useQueryGet from 'hooks/useQueryGet'
import { useAuthContext } from 'context/AuthContext/AuthContext'

const MarketEditForm = (): JSX.Element => {
  const navigate = useNavigate()
  const [errorMsg, setErrorMsg] = useState<string>('')
  const { t } = useTranslation()
  const { token, refreshToken } = useAuthContext()
  const params = useParams()
  const { changeMessage } = useAlert()

  const { data: continents, isLoading: loadingContinents } = useQueryGet<Continent[]>({
    queryKey: ['market-groups', 'continents'],
    endpoint: 'markets/groups'
  })

  const { data: marketLanguages, isLoading: loadingLanguages } = useQueryGet<ApiLanguage[]>({
    queryKey: ['markets-languages', params?.id],
    endpoint: `market-languages/${params.id ?? ''}`,
    queryOptions: { enabled: params?.id !== undefined }
  })

  const { mutate: editMarket, data: editResponse, status: editStatus, reset: editReset } = useMutation(
    async (market: DataToSend) => await axiosPost<Market | { message: string }>(token, refreshToken, `markets/update/${params.id ?? ''}`, { ...market }))

  const { data: marketData, isLoading: marketLoading } = useQueryGet<ApiMarket>({
    queryKey: ['market-details', params.id],
    endpoint: `markets/${params.id ?? 1}`,
    queryOptions: { enabled: params.id !== undefined, cacheTime: 0 }
  })

  const resetSuccess = (): void => {
    editReset()
    navigate('/markets')
  }

  useEffect(() => {
    if (editResponse !== undefined && 'error' in editResponse) {
      setErrorMsg(editResponse.error?.data.message)
    }
  }, [editResponse])

  const onHandleSubmit = async (data: HandleSubmitProps): Promise<any> => {
    setErrorMsg('')
    const body = {
      ...data,
      name: data.name.label,
      code: data.name.code,
      languages: data.languages,
      groupId: data.groupId,
      applicationHourReminder: Number(data.applicationHourReminder),
      holdingPageDate: format(new Date(data.holdingPageDate), 'yyyy-MM-dd HH:mm:ss+00:00'),
      promoLiveDate: format(new Date(data.promoLiveDate), 'yyyy-MM-dd HH:mm:ss+00:00'),
      promoTestLiveDate: format(new Date(data.promoTestLiveDate), 'yyyy-MM-dd HH:mm:ss+00:00'),
      promoEndDate: format(new Date(data.promoEndDate), 'yyyy-MM-dd HH:mm:ss+00:00'),
      closedPageEndDate: format(new Date(data.closedPageEndDate), 'yyyy-MM-dd HH:mm:ss+00:00'),
      personalDataDeletedBy: format(new Date(data.personalDataDeletedBy), 'yyyy-MM-dd HH:mm:ss+00:00'),
      forms: Object.entries(data.forms).map(([key, values]) => ({ name: key as MarketFormField, ...values }))
    }
    editMarket(body)
  }

  const languagesArray = Object.entries(languages).map(([key, lang]) => ({ key, name: lang.name, nativeName: lang.nativeName }))

  useEffect(() => {
    if (errorMsg !== '') {
      changeMessage(errorMsg, 'error', resetSuccess)
    }
    if (editResponse !== undefined && editStatus === 'success' && !('error' in editResponse) && !('message' in editResponse)) {
      changeMessage(t('forms.fieldUpdated', { field: t('singleField.market') }), 'success', resetSuccess)
    }
    if (editResponse !== undefined && editStatus === 'success' && !('error' in editResponse) && 'message' in editResponse) {
      changeMessage(editResponse.message, 'info', resetSuccess)
    }
  }, [errorMsg, editResponse, editStatus])

  if ((marketData === undefined || 'error' in marketData)) {
    return <RequestStatus data={marketData} isLoading={marketLoading} />
  }

  if (continents === undefined || 'error' in continents) {
    return (
      <RequestStatus data={continents} isLoading={loadingContinents} />
    )
  }

  if ((marketLanguages === undefined || 'error' in marketLanguages)) {
    return (
      <RequestStatus data={marketLanguages} isLoading={loadingLanguages} />
    )
  }

  return (
    <>
      <Typography variant='h4' component='h1' mb={2}>{t('common.editField', { field: 'Market' })}</Typography>
      <Card>
        <CardHeader title={<Button
          type='button'
          variant='outlined'
          onClick={() => { navigate('/markets') }}
          startIcon={<ChevronLeft />}
        >
          {t('common.backListText')}
        </Button>} />
        <CardContent>
          <Box sx={{ maxWidth: 640, width: '100%' }}>
            {continents !== undefined && marketLanguages !== undefined && marketLanguages !== null
              ? <MarketFormFields
                continents={continents}
                isAddForm={false}
                languagesArray={languagesArray}
                marketLanguages={marketLanguages}
                onHandleSubmit={onHandleSubmit}
                defaultValue={{
                  name: marketData.name !== 'undefined' ? marketData.name : '',
                  description: marketData.description !== 'undefined' ? marketData.description : '',
                  applicationHourReminder: `${marketData.applicationHourReminder}`,
                  phonePrefix: marketData.phonePrefix !== 'undefined' ? marketData.phonePrefix : '',
                  languages: marketData.languages ?? [],
                  groupId: marketData.groupId ?? 0,
                  holdingPageDate: marketData.holdingPageDate !== 'undefined' ? marketData.holdingPageDate : '',
                  promoLiveDate: marketData.promoLiveDate !== 'undefined' ? marketData.promoLiveDate : '',
                  promoTestLiveDate: marketData.promoTestLiveDate !== 'undefined' ? marketData.promoTestLiveDate : '',
                  promoEndDate: marketData.promoEndDate !== 'undefined' ? marketData.promoEndDate : '',
                  closedPageEndDate: marketData.closedPageEndDate !== 'undefined' ? marketData.closedPageEndDate : '',
                  personalDataDeletedBy: marketData.personalDataDeletedBy !== 'undefined' ? marketData.personalDataDeletedBy : '',
                  entryValidation: marketData.entryValidation ?? 0,
                  timeZone: marketData.timeZone ?? '',
                  entryValidationField: marketData.entryValidationField ?? 'NA',
                  entryValidationType: marketData.entryValidationType ?? 'NA',
                  flow: marketData.flow ?? '',
                  emailRecipient: marketData.emailRecipient ?? 'NA',
                  prize: marketData.prize ?? '',
                  verification: marketData.verification ?? 'email',
                  receiptValidation: marketData.receiptValidation ?? '',
                  emailService: marketData.emailService ?? '',
                  codes: marketData?.codes ?? [],
                  forms: Object.fromEntries(marketData?.forms.map(form => [form.name, { required: form.required, visible: true }])) as HandleSubmitProps['forms'] ?? {}
                }}
              />
              : null}
          </Box>
        </CardContent>
        <CardActions>
          <Button
            type='button'
            variant='outlined'
            onClick={() => { navigate('/markets') }}
            startIcon={<ChevronLeft />}
          >
            {t('common.backListText')}
          </Button>
        </CardActions>
      </Card>
    </>
  )
}

export default MarketEditForm

import React, { useEffect, createContext, useState, useContext } from 'react'
import type { User } from 'types/User'
import type { AppContextValue, AppProviderProps } from './AppContext.types'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { lightThemeOptions, darkThemeOptions } from 'config'
import CssBaseline from '@mui/material/CssBaseline'
import useMediaQuery from '@mui/material/useMediaQuery'
import { userDataName } from 'config/globalVariables'
import useQueryGet from 'hooks/useQueryGet'
import type { ArrayResponse } from 'components/AdvancedTable/AdvancedTable.types'
import type { Market } from 'types/Market'
import { useAuthContext } from 'context/AuthContext/AuthContext'
import { type ApiGlobalBarcode } from 'types/Barcode'
import { useAlert } from 'context/AlertContext'
const lightTheme = createTheme(lightThemeOptions)
const darkTheme = createTheme(darkThemeOptions)

const defaultValue: AppContextValue = {
  user: null,
  changeTheme: () => { },
  markets: undefined,
  isMarketsLoading: false,
  isDarkMode: false,
  handleRefetch: () => { }
}

export const AppContext = createContext(defaultValue)

export const useAppContext = (): AppContextValue => {
  const data = useContext(AppContext)

  return data
}

const AppProvider = ({ children }: AppProviderProps): JSX.Element => {
  const { token } = useAuthContext()
  const { changeMessage } = useAlert()
  const [user, setUser] = useState<User | null>(null)
  const [isDarkMode, setIsDarkMode] = useState(false)
  const [globalMarkets, setGlobalMarkets] = useState<ArrayResponse<Market> | undefined>(undefined)
  const [backupBarcodes, setBackupBarcodes] = useState<ArrayResponse<ApiGlobalBarcode> | undefined>(undefined)
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const { data, isLoading: isMarketsLoading, refetch } = useQueryGet<ArrayResponse<Market>>({
    queryKey: ['all-markets', token, user],
    endpoint: 'markets',
    options: { params: { page: 1, take: 100 } },
    queryOptions: { enabled: !!token }
  })
  const { data: barcodes } = useQueryGet<ArrayResponse<ApiGlobalBarcode>>({
    queryKey: ['backup-barcodes', token],
    endpoint: 'barcodes/global?backup=true',
    queryOptions: { enabled: !!token }
  })

  useEffect(() => {
    if (barcodes !== undefined && !('error' in barcodes)) {
      setBackupBarcodes(barcodes)
    }
  }, [barcodes])

  useEffect(() => {
    if (data !== undefined && !('error' in data)) {
      if ('error' in data) {
        changeMessage(data.error instanceof Object && 'message' in data.error ? '' : 'Something wrong with markets', 'error', () => { })
      } else {
        setGlobalMarkets(data)
      }
    }
  }, [data])

  useEffect(() => {
    const data = localStorage.getItem(userDataName)

    if (data !== null) {
      const userData = JSON.parse(data)
      setUser(userData)
    }
  }, [])

  useEffect(() => {
    const localStorageDarkMode = localStorage.getItem('dark-mode')

    if ((localStorageDarkMode !== null && localStorageDarkMode === 'true') || (localStorageDarkMode === null && prefersDarkMode)) {
      setIsDarkMode(true)
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('dark-mode', isDarkMode.toString())
  }, [isDarkMode])

  const changeTheme = (): void => { setIsDarkMode(!isDarkMode) }

  const handleRefetch = (): void => { void refetch() }

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <AppContext.Provider value={{ user, changeTheme, markets: globalMarkets, isMarketsLoading, isDarkMode, handleRefetch, barcodes: backupBarcodes }}>
        {children}
      </AppContext.Provider>
    </ThemeProvider>
  )
}

export default AppProvider

import React, { useEffect, useRef, useState } from 'react'
import type { ImportPrizesProps, ImportedPrize } from './ImportPrizes.types'
import Box from '@mui/material/Box'
import ButtonGroup from '@mui/material/ButtonGroup'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { excelExt } from 'config/extensions'
import { Can } from 'config/ability'
import { useMutation } from '@tanstack/react-query'
import { axiosPost } from 'connectors/axiosPost'
import type { AxiosResponse } from 'axios'
import ErrorView from 'components/ErrorView'
import { useNavigate } from 'react-router-dom'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'
import VerticalAlignTopOutlinedIcon from '@mui/icons-material/VerticalAlignTopOutlined'
import { useAlert } from 'context/AlertContext'
import useQueryGet from 'hooks/useQueryGet'
import RequestStatus from 'components/RequestStatus/RequestStatus'
import type { PrizeToImport } from 'types/Prize'
import Paper from '@mui/material/Paper'
import { useAuthContext } from 'context/AuthContext/AuthContext'
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import Modal from '@mui/material/Modal'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import PageLoader from 'components/PageLoader'

const PrizeElement = ({ prize: { prizeGroup, translation } }: { prize: PrizeToImport }): JSX.Element => {
  const [isCopied, setIsCopied] = useState(false)

  const copyHandler = async (text: string): Promise<void> => {
    setIsCopied(true)
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text)
    } else {
      document.execCommand('copy', true, text)
    }

    setTimeout(() => {
      setIsCopied(false)
    }, 500)
  }

  return (
    <ListItem
      sx={(theme) => ({ ':not(:last-of-type)': { borderBottom: `1px solid ${theme.palette.grey[500]}` }, paddingRight: 12 })}
      disableGutters
      secondaryAction={
        <Button variant='contained' color='info' onClick={async () => { await copyHandler(prizeGroup.name) }} startIcon={<ContentCopyOutlinedIcon />}>
          Copy
        </Button>
      }
    >
      <ListItemText primary={`${translation} (${prizeGroup.amountLeft}/${prizeGroup.totalAmount}): ${isCopied ? 'Copied...' : prizeGroup.name}`} />
    </ListItem>
  )
}

const ImportPrizes = ({ market }: ImportPrizesProps): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { changeMessage } = useAlert()
  const { token, refreshToken } = useAuthContext()
  const importInputRef = useRef<HTMLInputElement | null>(null)
  const [selectedFileToImport, setSelectedFileToImport] = useState<undefined | File>()
  const [showInstruction, setShowInstruction] = useState(false)

  const { mutate, isLoading, data, reset } = useMutation(async (data: FormData) => await axiosPost<ImportedPrize | { error: AxiosResponse | undefined }>(token, refreshToken, 'prize-draw/import', data), {
    onSuccess: (data, ctx) => {
      if (data !== undefined && !('error' in data) && ctx.get('approved') === 'true') {
        navigate('/applications')
      }
    }
  })

  useEffect(() => {
    reset()
    setSelectedFileToImport(undefined)
  }, [market])

  const { data: prizes, isLoading: isPrizesLoading } = useQueryGet<PrizeToImport[]>({
    queryKey: ['all-prizes', token, market],
    endpoint: 'prize-draw',
    options: { params: { marketId: market?.id ?? '' } }
  })

  useEffect(() => {
    if (data !== undefined && 'error' in data && data.error !== undefined && 'data' in data.error) {
      changeMessage(data?.error?.data?.message, 'error', reset)
    } else {
      changeMessage('Success', 'success', reset)
    }
  }, [data])

  const handleImportPrizes = (): void => {
    importInputRef.current?.click()
  }

  const handleSendPrizes = (): void => {
    if (selectedFileToImport !== undefined) {
      const fd = new FormData()
      if (market !== undefined) {
        fd.append('marketId', market.id.toString())
        fd.append('country', market.name)
      }
      fd.append('file', selectedFileToImport)
      mutate(fd)
    }
  }

  const sendApproved = (): void => {
    if (selectedFileToImport !== undefined) {
      const fd = new FormData()
      if (market !== undefined) {
        fd.append('marketId', market.id.toString())
        fd.append('country', market.name)
      }
      fd.append('file', selectedFileToImport)
      fd.append('approved', 'true')
      mutate(fd)
    }
  }

  if (data !== undefined && 'error' in data && typeof data?.error !== 'string' && data?.error !== undefined && 'status' in data?.error) {
    if (data?.error?.status === 401) {
      return <ErrorView type='privilages' />
    }

    if (data?.error?.status === 401) {
      return <ErrorView type='account' />
    }
  }

  if (prizes === undefined || 'error' in prizes) {
    return <RequestStatus data={prizes} isLoading={isPrizesLoading} />
  }

  return (
    <>
      <Box>
        <Paper sx={{ m: 1, p: 4 }}>
          <Typography fontWeight={700} >{t('prize.winners')} ({t('prize.clickToCopyName')})
            <IconButton color='info' onClick={() => { setShowInstruction(true) }} title={t('common.show') ?? ''}><HelpOutlineOutlinedIcon /></IconButton>:</Typography>
          <List sx={{ width: '100%', maxWidth: 400 }}>
            {prizes.map((prize) => <PrizeElement key={prize.prizeGroup.id} prize={prize} />)}
          </List>
          <Box sx={{ display: 'flex', gap: 3, alignItems: 'center', mt: 2 }}>
            <input
              ref={importInputRef}
              accept={excelExt.join(', ')}
              onChange={(e) => { setSelectedFileToImport(e.target.files?.[0]) }}
              type='file'
              style={{ position: 'absolute', width: '1px', height: '1px', opacity: 0 }}
            />
            <Can I='upload' a='Import prizes'>
              <ButtonGroup>
                <Button
                  onClick={handleImportPrizes}
                  type='button'
                  variant='contained'
                  color='primary'
                  startIcon={<VerticalAlignTopOutlinedIcon />}
                >
                  {t(selectedFileToImport !== undefined ? 'common.import' : 'common.importPrizesFile')}
                  &nbsp;
                  {selectedFileToImport !== undefined ? selectedFileToImport.name.slice(0, 20) : null}
                </Button>
                {selectedFileToImport !== undefined
                  ? <Button
                    onClick={handleSendPrizes}
                    type='button'
                    variant='contained'
                    color='success'
                    startIcon={<DoneOutlinedIcon />}
                  >
                    {t('common.send')}
                  </Button>
                  : null}
              </ButtonGroup>
            </Can>
          </Box>
        </Paper>
      </Box>
      {data !== undefined && data !== null && !('error' in data)
        ? <Box>
          <Paper sx={{ m: 1, p: 4 }}>
            <Alert severity="warning" sx={{ display: 'flex', alignItems: 'center', mb: 2 }} icon={<ReportOutlinedIcon fontSize='large' />}>
              <AlertTitle sx={{ fontSize: '18px', lineHeight: '24px', mb: 0 }}>{t('dialogs.afterImportPrizes', { recipient: t(`dialogs.${market?.emailRecipient === 'all' ? 'afterImportPrizesAll' : 'afterImportPrizesWinners'}`) })}</AlertTitle>
            </Alert>
            {Object.entries(data).map(([key, value], idx) => {
              const willRemain = value.amountLeft - value.winners.length
              const areEveryUsed = willRemain < 0

              return (
                <Box key={idx}>
                  <Typography color={areEveryUsed ? 'error' : 'GrayText'} fontWeight={areEveryUsed ? 'bold' : 'normal'}>
                    {key}, {t('prize.used')}: {value.winners.length}, {t('prize.available')}: {value.amountLeft}, {t('prize.willRemain')}: {willRemain} , {t('prize.periodTotal')}: {value.totalAmount}
                  </Typography>
                  <ul>
                    {value.winners !== undefined
                      ? value.winners.map((winner, idx) => (
                        <li key={idx}>
                          {winner.application_id}: {winner.application_email}
                        </li>
                      ))
                      : null}
                  </ul>
                </Box>
              )
            })}
            {data !== undefined && data !== null && !('error' in data)
              ? <Box onClick={sendApproved}>
                <Button color='success' variant='contained' startIcon={<DoneOutlinedIcon />}>
                  {t('common.send')}
                </Button>
              </Box>
              : null}
          </Paper>
        </Box >
        : null}
      <Modal
        open={showInstruction}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Paper sx={{ width: '100%', maxWidth: 640, maxHeight: '100vh', overflow: 'auto' }}>
          <Box p={4} display='flex' flexDirection='column'>
            <ol>
              <li>Please select the &apos;Applications&apos; tab in the left admin panel section and click on the &apos;Export&apos; button located in the top right corner.</li>
              <li>Choose the appropriate filters that refer to the entries you want to use, and then click on the &apos;Download&apos; button.</li>
              <li>Each time you download the data, you will be asked for the 6-digit temporary code available through the Google Authenticator app.</li>
              <li>The system will automatically create and initiate the download of a file containing all of the entries related to the filters you have defined.</li>
              <li>Prize draws are to be conducted locally, by your local customer service agencies.</li>
              <li>Once the draw has taken place and the winners have been selected, the downloaded file should be edited to include the winner&apos;s signature in the prize column on the lines containing the winner&apos;s details.</li>
              <li>The winner&apos;s signature is specific to your market. It can be found in the &apos;Prize Draw&apos; tab.</li>
              <li>The file should be saved in .xlsx format and then uploaded by clicking on the &apos;Import&apos; button located in the &apos;Prize Draw&apos; tab.</li>
              <li>If the upload is successful, the prize draw summary will be displayed for review.</li>
              <li>Please click the &apos;Send&apos; button to complete the process and automatically initiate the sending of the email communication to the winners based on the email templates you have provided.</li>
            </ol>
            <Button sx={{ alignSelf: 'flex-end' }} onClick={() => { setShowInstruction(false) }}>Close</Button>
          </Box>
        </Paper>
      </Modal>

      {isLoading && <PageLoader />}
    </>
  )
}

export default ImportPrizes
